<template>
  <div class="container">
    <a-page-header :title="pageTitle" @back="() => $router.go(-1)" />

    <div class="main-content">
      <div class="body">
        <div class="steps">
          <a-steps :current="stepsCurrent">
            <a-step title="基础信息" />
            <a-step title="绑定微信" />
          </a-steps>
        </div>

        <div class="form" v-if="stepsCurrent === 0">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="{ span: 5 }"
          >
            <a-form-model-item label="账户类型" prop="typeKey">
              <a-cascader
                :options="accountData"
                expand-trigger="hover"
                placeholder="请选择账户类型"
                :field-names="typeFieldNames"
                @change="onTypeChange"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="proxyId"
              label="代理企业"
              prop="proxyId"
              v-if="form.typeKey === 'Proxy'"
            >
              <a-select
                v-model="form.proxyId"
                @blur="
                  () => {
                    $refs.proxyId.onFieldBlur()
                  }
                "
                placeholder="请选择代理企业"
              >
                <a-select-option
                  v-for="item in proxys"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="所属地区"
              prop="areaId"
              v-if="form.typeKey === 'Proxy'"
            >
              <a-cascader
                :options="areas"
                @change="onAreaChange"
                placeholder="选择所属地区"
                :field-names="fieldNames"
                expand-trigger="hover"
                v-model="form.areaId"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="username"
              label="使用者姓名"
              prop="username"
              v-if="form.typeKey !== undefined"
            >
              <a-input
                v-model="form.username"
                placeholder="请输入使用者姓名"
                @blur="
                  () => {
                    $refs.username.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              ref="remark"
              label="备注"
              prop="remark"
              v-if="form.typeKey !== undefined"
            >
              <a-input
                type="textarea"
                v-model="form.remark"
                placeholder="请输入备注"
              />
            </a-form-model-item>
            <a-form-model-item class="btn">
              <a-button type="primary" @click="onSubmit">
                确认新增
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>

        <div
          class="qrcode-box"
          v-show="stepsCurrent === 1 && qrcodeScanStatu === null"
        >
          <iframe
            id="iframe"
            :src="wechatLink"
            width="300px"
            height="400px"
            frameborder="0"
            name="iframea"
          ></iframe>
          <div class="qrcode-p">务必让负责人扫码</div>
          <div class="qrcode-p">“进行绑定”</div>
        </div>

        <div class="bind-status" v-if="qrcodeScanStatu !== null">
          <div class="icon-box">
            <a-icon
              theme="filled"
              :style="{ color: '#09BB07', fontSize: '70px' }"
              type="check-circle"
            />
          </div>
          <div class="bind-title">绑定成功</div>
          <div class="bind-p">微信号：{{ realName }}</div>
          <div class="btn-box">
            <a-button class="bind-btn" @click="handleBack">
              返回列表
            </a-button>
            <a-button
              type="primary"
              class="bind-btn"
              @click="$jump({ path: '/', query: { id: userId } })"
            >
              分配角色
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      stepsCurrent: 0,
      form: {
        typeKey: undefined,
        areaId: undefined,
        proxyId: undefined,
        username: '',
        remark: ''
      },
      rules: {
        typeKey: [
          { required: true, message: '请选择账号类型', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择所属地区', trigger: 'change' }
        ],
        proxyId: [
          { required: true, message: '请选择代理企业', trigger: 'change' }
        ],
        username: [
          { required: true, message: '请输入使用者姓名', trigger: 'blur' }
        ]
      },
      accountData: [],
      typeFieldNames: {
        label: 'name',
        value: 'userType',
        children: 'role'
      },
      areas: [],
      proxys: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      selectAreaValue: 1,
      qrcodeScanStatu: null, // 绑定状态
      userId: '', // 新建账户id
      realName: '', // 微信号
      wechatLink: ''
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  created() {
    const query = this.$route.query
    console.log(query)
    if (query.code) {
      // 绑定微信
      this.stepsCurrent = 1
      const params = {
        userType: query.userType,
        id: query.id,
        code: query.code
      }
      this.wechatBind(params)
    } else if (query.name) {
      // 绑定成功
      this.stepsCurrent = 1
      this.qrcodeScanStatu = true
      this.realName = query.name
    } else {
      // 常规
      this.getAccountTypeData()
      this.getArea()
      this.getProxys()
    }
  },
  methods: {
    getAccountTypeData() {
      // 获取账号类型列表
      this.$axios.getAccountTypeData().then((res) => {
        this.accountData = res.data.data
      })
    },
    async getArea() {
      // 获取区域列表
      this.areas = await this.$axios.getArea()
    },
    onTypeChange(e) {
      this.form.typeKey = e[e.length - 1]
    },
    onAreaChange(e) {
      // 区域选择事件
      this.selectAreaValue = e.length
    },
    getProxys() {
      // 获取代理企业列表
      this.$axios.getProxys().then((res) => {
        this.proxys = res.data.data
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            type: this.form.typeKey,
            username: this.form.username,
            remark: this.form.remark
          }
          if (this.form.typeKey === 'Proxy') {
            const newData = {
              proxyId: this.form.proxyId,
              areaId: this.form.areaId[this.form.areaId.length - 1]
            }
            data = { ...data, ...newData }
          }

          this.$axios.newAccountNumber(data).then((res) => {
            this.stepsCurrent = 1
            this.userId = res.data.data
            const params = {
              userType: this.form.typeKey,
              id: res.data.data
            }
            this.getWechatBindLink(params)
          })
        }
      })
    },
    getWechatBindLink(params) {
      // 获取微信绑定链接
      this.$axios.getWechatBindLink(params).then((res) => {
        this.wechatLink = res.data.data.url // 二维码内容
      })
    },
    wechatBind(data) {
      // 绑定微信
      this.$axios.wechatBind(data).then((res) => {
        this.$router.replace({
          path: '/accountAdd',
          query: {
            name: res.data.data.nickname
          }
        })
      })
    },
    handleBack() {
      this.$router.go(-2)
    }
  }
}
</script>

<style scoped>
.main-content {
  display: flex;
  flex-direction: column;
}

.body {
  flex: 1;
  background-color: #fff;
}

.steps {
  width: 432px;
  padding-top: 48px;
  margin: 0 auto;
}

.form {
  width: 432px;
  padding-top: 40px;
  margin: 0 auto;
}

.form .ant-form-item {
  display: flex;
}

.form >>> .ant-form-item-control-wrapper {
  flex: 1;
}

.btn {
  margin-top: 32px;
  margin-left: 6.5em;
}

.qrcode-box {
  width: 300px;
  margin: 40px auto 0 auto;
  position: relative;
}

.qrcode-p {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.bind-status {
  width: 560px;
  margin: 40px auto 0 auto;
}

.bind-status .icon-box {
  width: 70px;
  height: 70px;
  margin: 30px auto;
}

.bind-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
}

.bind-p {
  text-align: center;
  font-size: 14px;
  height: 22px;
  margin: 8px auto 24px auto;
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-box .bind-btn:not(:last-child) {
  margin-right: 8px;
}
</style>
